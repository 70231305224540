.main {
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 10000;
  transform: translate3d(-10px, 10px, 0);
}

.toast {
  width: 320px;
  height: 56px;
  background: #fff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.24);
  margin-bottom: 10px;
  transform: translate3d(0%, 0, 0);
}

.info {
  flex: 1;
  display: flex;
  align-items: center;
}

.icon {
  margin: 0 12px;
}

.text {
  font-weight: 500;
  font-size: 16px;
  color: #333;
}

.button {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
}
