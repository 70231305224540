@import '~@podiumhq/podium-ui/build/styles/colors.scss';

.main {
  display: flex;
  padding-left: 40px;
}

.tab {
  height: 40px;
  background: none;
  font-weight: 400;
  font-size: 14px;
  color: $steel;
  padding: 0;
  border: none;
  border-bottom: 2px solid transparent;
  margin-right: 24px;
  cursor: pointer;
  outline: none;
}

.active {
  border-bottom: 2px solid $cobalt-blue;
  color: $cobalt-blue;
  font-weight: 500;
}
