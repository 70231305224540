@import '~@podiumhq/podium-ui/build/styles/colors.scss';

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 8px;
  cursor: pointer;
  background: transparent;
  border: none;
  line-height: 1;
  padding: 0;
  outline: none;
}

.text {
  font-family: Graphik;
  font-weight: 500;
  font-size: 14px;
  color: $jumbo;
  padding-top: 4px;
}

.disabled {
  opacity: 0.5;
  cursor: initial;
}
