@import '~@podiumhq/podium-ui/build/styles/colors.scss';
.main {
  position: relative;
  top: 2px;
  height: 36px;
  border-radius: 100px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.9px;
  outline: none;
  margin-left: 6px;
  color: $white;
  padding: 1px 6px 1px 8px;
  line-height: normal;
  cursor: default;

  &:first-child {
    margin-left: 10px;
  }
}

.clickable {
  cursor: pointer;
}

.primary {
  background: $podium-brand;
  color: $white;
  border-color: $podium-brand;
}

.primary:disabled {
  background: $jumbo;
  border-color: $jumbo;
  cursor: default;
}

.red {
  background: red;
  color: $white;
  border-color: red;
}

.secondary {
  background: $white;
  color: $jumbo;
  border-color: #ccc;
}

.secondaryGreen {
  padding-left: 24px;
  padding-right: 24px;
  height: 40px;
  background: $jumbo;
  color: $podium-brand;
  border-color: $podium-brand;
  &:hover {
    background-color: $podium-brand;
    color: white;
  }
}

.live,
.archived,
.watchlistedStatus,
.activeStatus,
.test,
.starter,
.blocked,
.reason,
.bundle,
.partial {
  height: 20px;
  font-size: 11px;
  text-transform: uppercase;
}

.live {
  background-color: $cobalt-blue;
}

.blocked {
  color: white;
  background-color: red;
}

.bundle {
  background-color: $cobalt-blue;
  display: inline-block;
  white-space: nowrap;
}

.archived {
  background-color: red;
}
.watchlistedStatus{
  background-color: red;
}
.activeStatus{
  background-color: green;
}
.reason{
  background-color: yellow;
  color:black;
}
.starter {
  background-color: $poppy-red;
  color: white;
}

.test {
  background-color: #bb56d1;
}

.partial {
  display: flex;
  width: 73px;
  padding-top: 3px;
  border-radius: 10px;
  background-color: $goldfish;
  justify-content: center;
}

.disabled {
  cursor: default !important;
}
