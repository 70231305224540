.main {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  text-align: center;
  font-weight: 600;
  overflow: hidden;
}

.default {
  @extend .main;
  line-height: 42px;
  font-size: 1rem;
}

.num {
  @extend .main;
  line-height: 41px;
  font-size: 1.5px;
}
