$font-family: Graphik, Helvetica, sans-serif;

html, body {
  font-family: $font-family;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.25;
}

@mixin font-weight-medium {
  font-weight: 500;
}

@mixin font-weight-demibold {
  font-weight: 600;
}

@mixin font-weight-heavy {
  font-weight: 700;
}
