@font-face {
  font-family: 'Graphik';
  src: url('https://assets.podium.com/fonts/Graphik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assets.podium.com/fonts/Graphik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assets.podium.com/fonts/Graphik-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assets.podium.com/fonts/Graphik-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
