html,
body,
#root {
  width: 100%;
  margin: 0;
  display: flex;
  flex-flow: column;
  height: 100%;
  min-height: 100% !important;
  max-height: 100% !important;
}

html {
  overflow-y: auto !important;
}

#googleMapsNode {
  display: none;
}

/**
 Replacing node-sass with dart-sass put in the following for the stylesheet.
 This results in the h* tags being rendered like a p tag.
  h1 h2 h3 h4 h5 h6 {
    font-size: inherit;
    font-weight: inherit;
  }
 It used to use the default browser stylesheet. I don't know how to set 
 reasonable defaults for this other than this. Changed all instances of
 h* that modified font-size or font-weight to div.
 */
/* h1 {
  font-size: 2em !important;
  font-weight: bolder !important;
}
h2 {
  font-size: 1.5em !important;
  font-weight: bolder !important;
}
h3 {
  font-size: 1.17em !important;
  font-weight: bolder !important;
}
h4 {
  font-size: 1em !important;
  font-weight: bolder !important;
}
h5 {
  font-size: .83em !important;
  font-weight: bolder !important;
}
h6 {
  font-size: .67em !important;
  font-weight: bolder !important;
} 
p {
    margin-block-end: 16px;
  margin-block-start: 16px;
}
*/
